var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"photos"},[(_vm.loading)?_c('div',{staticClass:"fullview"},[_c('div',{staticClass:"loading-spacer"}),(_vm.loading)?_c('vue-loading',{attrs:{"type":"spin","color":"#333","size":{ width: '300px', height: '300px' }}}):_vm._e()],1):_vm._e(),_vm._l((_vm.summaryData),function(place,index){return _vm._l((place.images),function(img){return _c('div',{key:place.id+img.name,staticClass:"block"},[_c('div',{staticClass:"header",style:([
						{
							'background-color': _vm.current_prop(place).color
							,'color': _vm.current_prop(place).text_color
						},	
					])},[_c('span',[_vm._v(_vm._s(place.id)+" "+_vm._s(place.name))]),_c('span',{staticClass:"name",style:([
						{
							'border-color': _vm.current_prop(place).text_color
							,'color': _vm.current_prop(place).text_color
						},	
					])},[_vm._v(_vm._s(_vm.current_prop(place).name))])]),_c('img',{attrs:{"src":img.img},on:{"click":function($event){return _vm.onClickPhoto(img.img)}}}),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icon_enlarge.svg")},on:{"click":function($event){return _vm.onClickPhoto(img.img)}}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(img.name)+"  "+_vm._s(_vm.waterLevelFormat(_vm.current_waterlevels[index])))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.last_update(place.lastUpdate)))])])})}),(_vm.showModal)?_c('div',{staticClass:"modal",on:{"click":function($event){return _vm.closeModal()}}},[_c('div',{staticClass:"box"},[_c('img',{attrs:{"src":_vm.modal_src}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }