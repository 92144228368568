<template>
	<v-container class="photos">
		<div class="fullview" v-if="loading">
			<div class="loading-spacer"></div>
			<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
		</div>
		<template v-for="(place, index) in summaryData" class="" >
			<div v-for="img in place.images" class="block" :key="place.id+img.name">
				<div class="header"
					:style="[
						{
							'background-color': current_prop(place).color
							,'color': current_prop(place).text_color
						},	
					]"
				><span>{{place.id}} {{place.name}}</span>
				<span class="name"
					:style="[
						{
							'border-color': current_prop(place).text_color
							,'color': current_prop(place).text_color
						},	
					]"
				>{{ current_prop(place).name }}</span>
				</div>
				<img :src=img.img @click=onClickPhoto(img.img) />
				<img src="~@/assets/icon_enlarge.svg" class="icon" @click=onClickPhoto(img.img) />
				<div class="name">{{img.name}}&nbsp;&nbsp;{{waterLevelFormat(current_waterlevels[index])}}</div>
				<div class="time">{{last_update(place.lastUpdate)}}</div>
			</div>
        </template>
		<div class="modal" v-if="showModal" @click="closeModal()" >
			<div class="box"  >
				<img :src=modal_src />
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import { VueLoading } from 'vue-loading-template'

export default {
	components: {
		VueLoading
	},
	async created() {
		this.loading = true;
		await this.$store.dispatch("login", {});
		for (let i = 0; i < this.summaryData.length; i++) {
			await this.$store.dispatch("getPlaceParameters", { index: i });
			let summary = this.summaryData[i];
			if (Object.keys(summary.waterlevel).length != 0) {
				this.current_waterlevels[i] = summary.waterlevel.level[summary.waterlevel.level.length-1];
			} else {
				this.current_waterlevels[i] = 0;
			}
		}
		this.loading = false;
	}
	,
	computed: {
		...mapState("places", ["summaryData"])
	},
	data() {
		return {
			loading: false,
			showModal: false,
			current_waterlevels: [],
			modal_src : ''
		};
	},
	methods:{
        last_update : function(d){ 
          const t = new Date(d)
          return '更新 : '+ this.dateFormat(t,'yyyy.MM.dd hh:mm')
        },
		current_prop: function(place){
			return this.$store.getters.getAlertColor(place)
		}
		,onClickPhoto(s){
          this.showModal = true
          this.modal_src = s
        }
        ,closeModal(){
          this.showModal = false;
        }
		,onClickHeader(place) {
			this.$router
				.push({ name: "Place", params: { id: place.id, item: place } })
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" >
.fullview {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.loading-spacer {
  height: 30%;
}
main { min-height: 100vh; }
.container.photos { 
	display: flex; flex-wrap: wrap;  justify-content: flex-start; padding: 1vw !important;
	.block { 
		width: 32.6%; margin: 0 1% 1% 0; background-color: white; border-radius: 10px; overflow:hidden; position: relative;
		.header { 
			.name { border:1px solid #fff; display: inline-block; padding: 0 .5em; margin-right:0 !important;  }
		}
		&:nth-child(3n) { margin-right: 0;  }
		img { 
			width: 100%; height: 250px;  object-fit: cover; cursor:pointer; 
			&.arrow { height: 20px; display: block; }
			&.icon { 
				width: 30px; height: 30px; position: absolute; top: 50px; right: 10px; border-radius: 0; background-color: rgba(255,255,255,.7);
				overflow: hidden; 
			}
		}
		.name { padding: .2em .4em; font-size: 18px; font-weight: bold; color: #666;  }
		.time { padding: 0 .4em .2em; color: #666; }
		@media screen and (max-width:720px){
			width: 100%; margin-right: 0; 
		}
	}
}
</style>